// src/components/Auth/Login.jsx
import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setError(null);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleGoogleLogin = async () => {
        try {
            await signInWithPopup(auth, provider);
            setError(null);
        } catch (error) {
            setError(error.message);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            {error && <div className="text-red-500 text-sm mb-4">{error}</div>}
            <div className="mb-4">
                <label className="block text-gray-700">Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 border rounded"
                    placeholder="Enter your email"
                />
            </div>
            <div className="mb-6 relative">
                <label className="block text-gray-700">Password</label>
                <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-2 border rounded"
                    placeholder="Enter your password"
                />
                <div className="absolute right-3 top-10 cursor-pointer" onClick={togglePasswordVisibility}>
                    {showPassword ? <BsEyeSlash /> : <BsEye />}
                </div>
            </div>
            <div className="mb-4">
                <button
                    onClick={handleLogin}
                    className="w-full bg-teal-600 text-white py-2 rounded"
                >
                    Login
                </button>
            </div>
            <div className="text-center mb-4">
                <p>or</p>
                <button
                    onClick={handleGoogleLogin}
                    className="w-full bg-red-500 text-white py-2 rounded mt-2"
                >
                    Sign in with Google
                </button>
            </div>
        </div>
    );
}
