// src/components/LoginRegister.jsx
import React, { useState } from 'react';
import Login from './Login';
import Register from './Register';

export default function AuthContainer() {
    const [isLogin, setIsLogin] = useState(true);

    return (
        <div className="max-w-md mx-auto bg-white shadow-md rounded p-6 mb-16 mt-24">
            <div className="text-center mb-6">
                <h2 className="text-2xl font-bold">
                    {isLogin ? 'Login' : 'Register'}
                </h2>
            </div>

            {/* Tabs for Login / Register */}
            <div className="flex justify-center mb-4">
                <button 
                    className={`px-4 py-2 ${isLogin ? 'bg-teal-600 text-white' : 'bg-gray-200'} rounded-l`}
                    onClick={() => setIsLogin(true)}
                >
                    Login
                </button>
                <button 
                    className={`px-4 py-2 ${!isLogin ? 'bg-teal-600 text-white' : 'bg-gray-200'} rounded-r`}
                    onClick={() => setIsLogin(false)}
                >
                    Register
                </button>
            </div>

            {/* Render Login or Register Component */}
            {isLogin ? <Login /> : <Register />}
        </div>
    );
}
