// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'; 
import { getAnalytics } from "firebase/analytics";

// Firebase configuration can be loaded from .env file, but it does not make any sense.
const firebaseConfig = {
  apiKey: "AIzaSyAso-0ROPQ5g4SSUa-LzopkWDxYftY1mEM",
  authDomain: "ul-top.firebaseapp.com",
  projectId: "ul-top",
  storageBucket: "ul-top.appspot.com",
  messagingSenderId: "401444796930",
  appId: "1:401444796930:web:c512e482bb867304c2851b",
  measurementId: "G-RPZ3PXWZM1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase services and export them
const auth = getAuth(app);

export { auth, app, analytics };
