// src/components/Auth/Register.jsx
import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

export default function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);

    const auth = getAuth();

    const handleRegister = async () => {
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            await createUserWithEmailAndPassword(auth, email, password);
            setError(null);
        } catch (error) {
            setError(error.message);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            {error && <div className="text-red-500 text-sm mb-4">{error}</div>}
            <div className="mb-4">
                <label className="block text-gray-700">Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 border rounded"
                    placeholder="Enter your email"
                />
            </div>
            <div className="mb-4 relative">
                <label className="block text-gray-700">Password</label>
                <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-2 border rounded"
                    placeholder="Enter your password"
                />
                <div className="absolute right-3 top-10 cursor-pointer" onClick={togglePasswordVisibility}>
                    {showPassword ? <BsEyeSlash /> : <BsEye />}
                </div>
            </div>
            <div className="mb-6 relative">
                <label className="block text-gray-700">Confirm Password</label>
                <input
                    type={showPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full p-2 border rounded"
                    placeholder="Confirm your password"
                />
                <div className="absolute right-3 top-10 cursor-pointer" onClick={togglePasswordVisibility}>
                    {showPassword ? <BsEyeSlash /> : <BsEye />}
                </div>
            </div>
            <div className="mb-4">
                <button
                    onClick={handleRegister}
                    className="w-full bg-teal-600 text-white py-2 rounded"
                >
                    Register
                </button>
            </div>
        </div>
    );
}
