import React from 'react';
import { Routes, Route } from 'react-router-dom';
//import RootPage from './pages/RootPage';
import RedirectionPage from './pages/RedirectionPage';
import ClientPage from './pages/ClientPage';

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<ClientPage />} />
      <Route path="/:slug" element={<RedirectionPage />} />
    </Routes>
  );
}