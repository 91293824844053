// src/pages/ClientPage.jsx
import React from 'react';
import DefaultLayout from "../layouts/DefaultLayout";
import ClientArea from '../components/ClientArea';
import { useAuth } from '../context/AuthContext';
import AuthContainer from '../components/Auth/AuthContainer';

export default function ClientPage() {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <DefaultLayout>
        {/* TODO: replace with loading component like skeleton */}
        <p>Loading...</p>;
      </DefaultLayout>
    );
  } else {
    return (
      <DefaultLayout>
        {user ? (
          <>
            <ClientArea />
          </>
        ) : (
          <>
            <AuthContainer />
          </>
        )}
      </DefaultLayout>
    );
  }
}
